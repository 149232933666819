import React, { useEffect } from "react"
// Components
import { graphql } from 'gatsby';
import { Router } from "@reach/router"
import Layout from "../components/layout"

export const query = graphql`
  query PostsQuery {
    allSanityPost {
      edges {
        node {
          shortCodeNumber {
            current
          }
          slug {
            current
          }
        }
      }
    }
  }
`;

const ShortCodeRedirect = ({ posts,shortCode }) => {
  const post = posts.filter(post => post.shortCode === shortCode);

  useEffect(() => {
    const checkGA = setInterval(function() {
      if (window.gtag) {
        if(typeof window !== "undefined") {
          window.gtag('event', 'redirect', {
            'event_category': 'ShortCode',
            'event_action': 'redirect',
            'event_label': shortCode + " > " + (post.length ? post[0].slug : "not found")
          });
        }
        clearInterval(checkGA);
      }
      window.location.href = (post.length ? window.location.origin + "/post/" + post[0].slug : "/404");
   }, 100);
    return () => clearInterval(checkGA);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return null;
}

const ShortCodePage = ({ data }) => {
  const posts = data.allSanityPost.edges.map(({ node: post }) => {
    return {
      shortCode: post.shortCodeNumber.current,
      slug: post.slug.current
    }
  });

  return (
    <Layout>
      <Router basepath="/p">
        <ShortCodeRedirect path="/:shortCode" posts={posts} />
      </Router>
    </Layout>
  )
}

export default ShortCodePage;
